export interface RequestedDocument {
  id: string;
  orderId: string;
  title: string;
  version: number;
  quantity: number;
  lastUpdate: string;
  status: string;
  type: string;
  template: string;
  docxId: string;
  pdfId: string;
  pdfPrintId: string;
}

export enum DocumentType {
  DOCX = "DOCX", PDF_DRAFT = "PDF_DRAFT", PDF_PRINT = "PDF_PRINT"
}
