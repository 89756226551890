import { Injectable } from '@angular/core';
import { RequestedDocument } from '../../order/order-detail/form/documents/document-table/requestedDocument';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageResult } from '../model/page-result.model';
import { PageRequest } from '../model/page-request.model';
import { PdfType } from '../model/pdf-type.enum';

@Injectable({
  providedIn: 'root',
})
export class RequestedDocumentsService {
  constructor(private httpClient: HttpClient) {}

  getRequestedDocumentsForOrder(
    orderId: string,
    pageRequest: PageRequest,
  ): Observable<PageResult<RequestedDocument>> {
    const params = pageRequest.toParams();
    return this.httpClient.get<PageResult<RequestedDocument>>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument`,
      { params },
    );
  }

  updatedRequestedDocuments(
    newRequestedDocument: RequestedDocument,
  ): Observable<void> {
    const orderId = newRequestedDocument.orderId;
    return this.httpClient.post<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument`,
      { ...newRequestedDocument },
    );
  }

  generateDocument(
    orderId: string,
    requestedDocumentId: string,
  ): Observable<void> {
    return this.httpClient.get<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/generate`,
    );
  }

  generatePdf(orderId: string, requestedDocumentId: string): Observable<void> {
    return this.httpClient.get<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/generate-pdf-draft`,
    );
  }

  generatePdfPrint(
    orderId: string,
    requestedDocumentId: string,
  ): Observable<void> {
    return this.httpClient.get<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/generate-pdf-print`,
    );
  }

  discardDocument(
    orderId: string,
    requestedDocumentId: string,
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/discard`,
    );
  }

  generateAllDocuments(orderId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.baseUrl}/order/${orderId}/requestedDocument/generateAll`,
      {},
    );
  }

  getDocxDownloadEndpoint(
    orderId: string,
    requestedDocumentId: string,
  ): string {
    return `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/docx`;
  }

  getPdfDownloadEndpoint(
    orderId: string,
    requestedDocumentId: string,
    pdfType: PdfType,
  ): string {
    return `${environment.baseUrl}/order/${orderId}/requestedDocument/${requestedDocumentId}/pdf/${pdfType}`;
  }

  getAllDocxDownloadEndpoint(orderId: string): string {
    return `${environment.baseUrl}/order/${orderId}/requestedDocument/downloadAll`;
  }
}
