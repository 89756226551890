import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DocumentType, RequestedDocument } from './requestedDocument';
import { RequestedDocumentsService } from '../../../../../shared/service/requestedDocuments.service';
import { MatSortable } from '@angular/material/sort';
import { PageRequest } from '../../../../../shared/model/page-request.model';
import { Order } from '../../../../order.model';
import { PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { UserHttpService } from '../../../../../shared/service/user-http.service';
import { AuthorizedUser, hasAnyOfThoseRoles, UserRole, } from '../../../../../shared/model/user.model';
import { CxTableLabelTypeEnum } from '@bbraun/cortex/table-utilities';
import { PdfType } from '../../../../../shared/model/pdf-type.enum';

@Component({
  selector: 'hpm-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() documentsDisabled = false;
  requestedDocuments: RequestedDocument[] = [];
  private onDestroy$: Subject<void> = new Subject();
  currentUser: AuthorizedUser | null = null;
  columns: string[] = [
    'title',
    'version',
    'quantity',
    'lastUpdate',
    'status',
    'menu',
  ];
  readonly defaultPageSize: number = 10;

  tableLoading = false;
  totalElementCount = 0;
  tableSettings: {
    pageSize: number;
    pageNumber: number;
    sort: { id: string; start: 'asc' | 'desc' | '' };
  } = {
    pageSize: this.defaultPageSize,
    pageNumber: 0,
    sort: {id: 'title', start: 'asc'},
  };

  protected readonly tableLabelType = CxTableLabelTypeEnum;
  protected readonly pdfType = PdfType;
  protected readonly documentType = DocumentType;

  constructor(
    private requiredDocumentsService: RequestedDocumentsService,
    private cdr: ChangeDetectorRef,
    private userService: UserHttpService,
  ) {
  }

  ngOnInit(): void {
    this.loadRequiredDocuments();
    this.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSortingDirectionChanged(sortData: MatSortable): void {
    this.tableSettings.pageNumber = 0;
    this.tableSettings.sort = {
      start: sortData.start,
      id: sortData.id,
    };
    this.loadRequiredDocuments();
  }

  onPaginatorChange(changeData: PageEvent): void {
    this.tableSettings.pageNumber = changeData.pageIndex;
    this.tableSettings.pageSize = changeData.pageSize;
    this.loadRequiredDocuments();
  }

  private loadRequiredDocuments(): void {
    const pageRequest = new PageRequest({
      page: this.tableSettings.pageNumber,
      size: this.tableSettings.pageSize,
    });
    if (this.tableSettings.sort && this.tableSettings.sort.start !== '') {
      this.tableLoading = true;
      pageRequest.sort = this.tableSettings.sort;
    }
    if (this.order.id) {
      this.tableLoading = true;
      this.requiredDocumentsService
        .getRequestedDocumentsForOrder(this.order.id, pageRequest)
        .subscribe((requiredDocuments) => {
          this.requestedDocuments = requiredDocuments.content;
          this.totalElementCount = requiredDocuments.totalElements;
          this.tableLoading = false;
          this.cdr.detectChanges();
        });
    }
  }

  setQuantityForDocument(documentIndex: number, value: number): void {
    if (documentIndex >= this.requestedDocuments.length || value < 0) {
      return;
    }
    const newRequestedDocument: RequestedDocument =
      this.requestedDocuments[documentIndex];

    newRequestedDocument.quantity = value;
    this.updateRequestedDocuments(newRequestedDocument);
  }

  getRequestedDocumentQuantity(documentIndex: number): number {
    if (documentIndex >= this.requestedDocuments.length) {
      return 0;
    }
    return this.requestedDocuments[documentIndex].quantity;
  }

  private updateRequestedDocuments(
    newRequestedDocument: RequestedDocument,
  ): void {
    this.requiredDocumentsService
      .updatedRequestedDocuments(newRequestedDocument)
      .subscribe();
  }

  generateDocument(id: string): void {
    this.tableLoading = true;
    this.requiredDocumentsService
      .generateDocument(this.order.id!, id)
      .subscribe(() => {
        this.loadRequiredDocuments();
        this.tableLoading = false;
      });
  }

  generatePdf(id: string): void {
    this.tableLoading = true;
    this.requiredDocumentsService
      .generatePdf(this.order.id!, id)
      .subscribe(() => {
        this.loadRequiredDocuments();
        this.tableLoading = false;
      });
  }

  generatePdfPrint(id: string): void {
    this.tableLoading = true;
    this.requiredDocumentsService
      .generatePdfPrint(this.order.id!, id)
      .subscribe(() => {
        this.loadRequiredDocuments();
        this.tableLoading = false;
      });
  }

  generateAllDocument(): void {
    this.tableLoading = true;
    this.requiredDocumentsService
      .generateAllDocuments(this.order.id!)
      .subscribe(() => {
        this.loadRequiredDocuments();
      });
  }

  isPossibleToGenerate(): boolean {
    return this.requestedDocuments.some(
      (document) => document.status === 'REQUESTED',
    );
  }

  getDocxUrl(requestedDocumentId: string): string {
    return this.requiredDocumentsService.getDocxDownloadEndpoint(
      this.order.id!,
      requestedDocumentId,
    );
  }

  getPdfUrl(requestedDocumentId: string, pdfType: PdfType): string {
    return this.requiredDocumentsService.getPdfDownloadEndpoint(
      this.order.id!,
      requestedDocumentId,
      pdfType,
    );
  }

  isPdfGenerated(document: RequestedDocument): boolean {
    return !!document.pdfId;
  }

  isPdfPrintGenerated(document: RequestedDocument): boolean {
    return !!document.pdfPrintId;
  }

  isDocxGenerated(document: RequestedDocument): boolean {
    return !!document.docxId;
  }

  getAllDocxUrl(): string {
    return this.requiredDocumentsService.getAllDocxDownloadEndpoint(
      this.order.id!,
    );
  }

  getCurrentUser(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser;
      });
    this.cdr.detectChanges();
  }

  allowedToGenerateOrEdit(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
    ]);
  }

  isHygienePlan(requestedDocument: RequestedDocument): boolean {
    return requestedDocument.type === 'HYGIENEPLAN';
  }

  allowedToDownload(document: RequestedDocument, documentType: DocumentType): boolean {
    return (
      (hasAnyOfThoseRoles(this.currentUser, [
          UserRole.ADMIN,
          UserRole.OFFICE_SERVICE,
        ]) &&
        document.status === 'GENERATED') ||
      (hasAnyOfThoseRoles(this.currentUser, [
          UserRole.RETAIL_PARTNER,
          UserRole.FIELD_SERVICE,
        ]) &&
        (document.status === 'GENERATED' &&
        this.order.assignedTo?.id === this.currentUser?.id
        && ((documentType === DocumentType.PDF_DRAFT && !this.isPdfPrintGenerated(document))
            || documentType === DocumentType.PDF_PRINT)))
    );
  }

  canBeDownloaded(): boolean {
    return (
      (hasAnyOfThoseRoles(this.currentUser, [
          UserRole.ADMIN,
          UserRole.OFFICE_SERVICE,
        ]) &&
        this.requestedDocuments.some(
          (document) => document.status === 'GENERATED',
        )) ||
      (hasAnyOfThoseRoles(this.currentUser, [
          UserRole.RETAIL_PARTNER,
          UserRole.FIELD_SERVICE,
        ]) &&
        this.requestedDocuments.some(
          (document) => document.status === 'GENERATED' &&
            (this.isPdfGenerated(document) || this.isPdfPrintGenerated(document)),
        ) &&
        this.order.assignedTo?.id === this.currentUser?.id)
    );
  }

  allowedToDiscard(): boolean {
    return hasAnyOfThoseRoles(this.currentUser, [
      UserRole.ADMIN,
      UserRole.OFFICE_SERVICE,
    ]);
  }

  discardDocument(id: string, confirmation: boolean): void {
    if (confirmation) {
      this.requiredDocumentsService
        .discardDocument(this.order.id!, id)
        .subscribe(() => {
          this.loadRequiredDocuments();
        });
    }
  }
}
