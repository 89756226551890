import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Order } from '../../../order.model';
import { Subject, takeUntil } from 'rxjs';
import { UserHttpService } from '../../../../shared/service/user-http.service';

@Component({
  selector: 'hpm-hygiene-inspection',
  templateUrl: './hygiene-inspection.component.html',
  styleUrl: './hygiene-inspection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HygieneInspectionComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
  private onDestroy$: Subject<void> = new Subject<void>();
  hygieneInspectionDisabled = false;

  currentStep = 1;

  constructor(private userService: UserHttpService) {}

  ngOnInit(): void {
    this.disableHygieneInspection();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setCurrentStep(step: number): void {
    this.currentStep = step;
  }

  nextStep(): void {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  prevStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  disableHygieneInspection(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        if (currentUser?.id !== this.order.assignedTo?.id) {
          this.hygieneInspectionDisabled = true;
        }
      });
  }
}
